import { PortalRequest as request } from '@/utils/request'

// 我的订单（国产替代）
export const getMyOrderList = (params, data) => {
  return request({
    url: 'order/h5/page-list-me',
    method: 'post',
    params: params,
    data
  })
}

// 创建订单（国产迁移需求）
export const createOrder = (data) => {
  return request({
    url: '/order/save',
    method: 'post',
    data
  })
}

// 订单信息表详情
export const getOrderInfo = (id) => {
  return request({
    url: `/order/${id}`,
    method: 'get',
  })
}
