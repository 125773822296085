<template>
  <div class="demand-move" v-wechat-title="'我有国产迁移需求'">
    <strong>{{
      demandMoveTag == 1 ? "您的国产化迁移需求" : "您的联系信息"
    }}</strong>
    <p>鸿联联创平台的技术专家会为您提供最专业的解答！</p>
    <!-- <p class="add-demand-ts">添加设备后需点下一步提交</p> -->
    <div class="demand-move-equipment" v-if="demandMoveTag == 1">
      <div class="demand-move-equipment-type">
        <p><span>*</span>我想迁移适配</p>
        <span @click="sheetShow = true">
          <template v-if="demandTypeId == ''">
            请选择
          </template>
          <template v-else>
            {{ demandTypeId == "1" ? "硬件产品" : '模组' }}
          </template>
          <van-icon name="arrow"/>
        </span>
      </div>
      <div class="demand-move-equipment-box">
        <div class="demand-move-equipment-title">
          <p><span>*</span>添加设备</p>
        </div>
        <div class="demand-move-equipment-list">
          <div
            v-for="(el, i) in demandList"
            :key="i"
            @click="equipmentAdd('edit', i)"
          >
            <p>{{ el.deviceName }}<van-icon name="arrow" /></p>
            <span>{{ el.deviceMmodel }}</span>
          </div>
        </div>
      </div>
      <div class="apply-dev-form-btn">
        <van-button block class="btn-red" :disabled="demandList == ''" :style="{opacity:demandList == ''?'0.5 !important':'1 !important'}" @click="demandMoveTag = 2"
          >下一步</van-button
        >
      </div>
    </div>
    <div class="demand-move-add" v-if="demandMoveTag == 1">
      <span @click="equipmentAdd('add', '')"
        ><van-icon name="plus" /> 添加设备</span
      >
    </div>
    <div v-if="demandMoveTag == 2">
      <van-form ref="formData" validate-first @submit="onFailed">
        <van-field
          label="企业名称"
          required
          v-model="form.companyName"
          name="企业名称"
          placeholder="请输入企业名称"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <van-field
          label="联系人"
          required
          v-model="form.name"
          name="联系人"
          placeholder="请填写联系人"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <van-field
          label="手机号"
          required
          v-model="form.contactPhone"
          name="手机号"
          maxlength="11"
          placeholder="请填写手机号"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <van-field
          label="企业所在地"
          v-model="form.address"
          type="textarea"
          name="企业所在地"
          placeholder="请输入企业所在地"
          :autosize="{ maxHeight: 100, minHeight: 100 }"
          class="apply-dev-form-text"
        />
        <div class="apply-dev-form-btn">
          <van-button block class="btn-red" native-type="button"
            :disabled="
              form.companyName == '' ||
              form.name == '' ||
              form.contactPhone == ''
            "
            :class="{
              'apply-dev-form-submit':
                form.companyName != '' &&
                form.name != '' &&
                form.contactPhone != ''
            }"
            @click="orderSave"
          >保存</van-button>
          <van-button
            block
            class="btn-red"
            native-type="submit"
            :disabled="
              form.companyName == '' ||
              form.name == '' ||
              form.contactPhone == ''
            "
            :class="{
              'apply-dev-form-submit':
                form.companyName != '' &&
                form.name != '' &&
                form.contactPhone != ''
            }"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-action-sheet
      v-model="sheetShow"
      :actions="sheetActions"
      cancel-text="取消"
      close-on-click-action
      @select="onselect"
      @cancel="onCancel"
    />
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createOrder,getOrderInfo } from "@/api/order";
import {getCurrentCompany} from '@/api/res-company'
import share from '../components/share.vue'
export default {
  components:{
    share
  },
  data() {
    return {
      sheetShow: false,
      sheetActions: [
        { name: "硬件产品", id: "1" },
        { name: "模组", id: "2" },
      ],
      demandType: "硬件产品",
      demandTypeId: "",
      demandMoveTag: 1,
      form: {
        companyName: "",
        name: "",
        contactPhone: "",
        address: "",
      },
      companyInfo:'',
      demandList: [],
      shareInfo: {
          title: '您的国产化迁移需求',
          desc: '鸿联联创平台的技术专家会为您提供最专业的解答！',
          img: require('@/assets/img/logoinimg.png')
      },
      submitType:'2',//点击的是保存还是提交 2 提交，1保存
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    orderId: function () {
      return this.$route.query.orderId;
    },
  },
  created(){
    this.orderVal = JSON.parse(localStorage.getItem('orderVal'))
  },
  mounted() {
    if (this.$route.query.list) {
      let list = JSON.parse(this.$route.query.list);
      this.demandList = list;
      this.demandTypeId = this.$route.query.demandTypeId
    }
    if(this.orderVal){
      let editVal = this.orderVal
      this.form.companyName = editVal.companyName
      this.form.name = editVal.contactPerson
      this.form.contactPhone = editVal.contactPhone
      this.form.address = editVal.companyAddress
      this.demandTypeId = editVal.adaptationType
      this.loadOrderInfo()
    }else{
      this.form.companyName = this.userInfo.companyName
      this.form.name = this.userInfo.realName
      this.form.contactPhone = this.userInfo.phone
    }

    if (this.$route.params.form){
      this.form = this.$route.params.form
    }
    if(this.userInfo){
      this.loadCompanyInfo()
    }

  },
  methods: {
    loadOrderInfo() {
      getOrderInfo(this.orderId).then((res) => {
        console.log(res)
        for (let index = 0; index < res.orderDeviceList.length; index++) {
          let obj = {
            deviceName: res.orderDeviceList[index].deviceName,
            deviceMmodel: res.orderDeviceList[index].deviceModel,
            socTest: res.orderDeviceList[index].soc,
            socDesc: res.orderDeviceList[index].socOther,
            systemTest: res.orderDeviceList[index].os,
            systemDesc: res.orderDeviceList[index].osOther,
            socMmodel: res.orderDeviceList[index].socModel,
            uploadList:res.orderDeviceList[index].deviceAttmIds
          };
          this.demandList.push(obj)
        }
      });
    },
    loadCompanyInfo(){
      getCurrentCompany().then(res=>{
        this.companyInfo=res
        this.form.companyName=res.companyName
      })


    },

    onCancel() {
      this.sheetShow = false;
    },
    onselect(val) {
      this.demandType = val.name;
      this.demandTypeId = val.id;
    },
    // 提交
    onFailed(values) {
      let { form, demandList,submitType,orderId } = this;
      if (!/^1[3456789]\d{9}$/.test(form.contactPhone)) {
        this.$toast("手机号格式不正确");
        return
      }
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let data = {
        orderDeviceList: [],
        companyName: form.companyName,
        companyType: "",
        contactPerson: form.name,
        contactPhone: form.contactPhone,
        companyAddress: form.address,
        orderStatus: submitType,
        adaptationType: this.demandTypeId,
      };
      if(orderId){
        data.id = orderId
      }
      for (let index = 0; index < demandList.length; index++) {
        let obj = {
          deviceName: demandList[index].deviceName,
          deviceModel: demandList[index].deviceMmodel,
          soc: demandList[index].socTest,
          socOther: demandList[index].socDesc,
          os: demandList[index].systemTest,
          osOther: demandList[index].systemDesc,
          socModel: demandList[index].socMmodel,
          deviceAttmIds: demandList[index].uploadList,
          // fileList: JSON.stringify(demandList[index].fileList),
        };
        data.orderDeviceList.push(obj);
      }
      data.companyId=this.companyInfo.id
      createOrder(data).then((res) => {
        this.$toast.clear();
        this.$toast.success('提交成功！');
        localStorage.removeItem('orderVal')
        setTimeout(() => {
          this.$router.push("/myDemand");
        }, 1500);
      });
    },
    // 保存
    orderSave(){
      this.submitType = '1'
      this.$refs.formData.submit();
      localStorage.removeItem('orderVal')
    },
    equipmentAdd(type, index) {
      console.log(this.orderId)
      if(this.demandTypeId == ''){
        this.$toast("请选择我想迁移的适配");
        return
      }
      if (type == "edit") {
        this.$router.push({
          name: "demandMoveAdd",
          params:{
            list: this.demandList,
            form: this.form
          },
          query: {
            type,
            index,
            demandType:this.demandTypeId,
            orderId:this.orderId
          },
        });
      } else {
        this.$router.push({
          name: "demandMoveAdd",
          params:{
            list: this.demandList,
            form: this.form
          },
          query: {
            type,
            demandType:this.demandTypeId,
            orderId:this.orderId
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.demand-move {
  padding: 24px 16px 100px;
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  > strong {
    display: block;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 5px;
  }
  > p {
    font-size: 12px;
    line-height: 17px;
    color: #999999;
    margin-bottom: 6px;
  }
  .demand-move-equipment {
    background: #fff;
    padding: 0 12px;
    .demand-move-equipment-type {
      height: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      > p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: #333;
        > span {
          color: #ea0b06;
        }
      }
      > span {
        color: #999;
      }
    }
    .demand-move-equipment-box {
      padding: 2px 0;
      font-size: 14px;
      .demand-move-equipment-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        > p {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;
          > span {
            margin-right: 2px;
            color: #ea0b06;
          }
        }
        > img {
          width: 16px;
        }
      }
      .demand-move-equipment-list {
        > div {
          margin-bottom: 16px;
          > p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 20px;
            color: #333;
            margin-bottom: 6px;
            > i {
              color: #999;
            }
          }
          > span {
            font-size: 12px;
            line-height: 17px;
            color: #999999;
            display: block;
          }
        }
      }
    }

    .apply-dev-form-btn {
      > button {
        opacity: 1 !important;
        margin: 0 !important;
      }
    }
  }
  .demand-move-add {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    > span {
      height: 30px;
      background: #ffffff;
      border-radius: 19px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      font-size: 14px;
      color: #666666;
      > i {
        margin-right: 4px;
      }
    }
  }
  .add-demand-ts {
    font-size: 12px;
    line-height: 17px;
    color: #999999;
    line-height: 17px;
  }
  .apply-dev-form-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 16px;
    display: flex;
    margin-top: 40px;
    > button:first-child {
      margin-right: 10px;
    }
    > button:last-child {
      opacity: 0.5;
    }
    .apply-dev-form-submit {
      opacity: 1 !important;
    }
  }
}
</style>
<style>
.demand-move .van-cell {
  padding: 17px 12px;
}
.demand-move .van-field__label {
  margin-left: 2px;
}
.apply-dev-form-inp .van-field__control{
  text-align: right;
}
.apply-dev-form-text{
  display: block;
}
.apply-dev-form-text .van-cell__value{
  width: 100%;
  padding: 10px;
  background: #F9F9FB;
  margin-top: 12px;
}
</style>
